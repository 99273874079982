<template>
  <b-container class="c-page">
    <div class="c-page__title">
      <div class="d-flex w-100 justify-content-between">
        <h1>{{ $t('projects.title') }}</h1>
        <div v-if="role == 'admin'" class="d-flex">

          <b-form-select v-model="export_type" @change="setNumberOfCall()" size="sm" :options="projectType"></b-form-select>
          <b-form-input v-model="export_number_of_call" class="flex-grow-0 ml-2" type="number" style="width: 50px" size="sm"
                        placeholder="Enter your name"></b-form-input>
          <b-button class="float-left align-self-start ml-2 text-nowrap" size="sm" @click="exportDatabank()"
                    variant="primary">
            <b-icon aria-hidden="true" icon="download"></b-icon>&nbsp;
            {{ $t('universalTxt.exportDatabank') }}
          </b-button>
        </div>

      </div>
    </div>
    <!-- user  -->
    <div v-if="role == 'user' " class="row">
      <div class="col-12 mb-4">
        <b-button variant="primary" class="mr-3" @click="addItem()">
          <b-icon icon="plus-square-fill" variant="white"
          ></b-icon>&nbsp;
          {{ $t('projects.newProject') }}
        </b-button>
      </div>
      <div v-for="item in posts" :key="item.id" class="col-12 mb-4">
        <b-card body-class="c-card-body">
          <i>{{ item['name_' + $i18n.locale] }}</i>


          <div v-if="item.status != ''">
            <hr class="w-100">
            <h3>Status:</h3>
            <p>{{ $t('statesBtn.' + item.status) }}</p>
          </div>
          <hr class="w-100">

          <div class="c-card-body__wrap" :class="{'c-card-body__wrap--disabled' : item.edit_locked }">
            <label>{{ $t('projects.phase_1') }}</label>
            <router-link :to="{name: 'projectsEdit', params: {id: item.id}}" :title="$t('universalTxt.edit')">
              <b-button variant="primary" size="sm">
                <b-icon class="clickable" icon="pencil-square" variant=""></b-icon>
                <span>&nbsp;{{ $t('universalTxt.edit') }}</span>
              </b-button>
            </router-link>

          </div>
          <hr class="w-100">
          <div class="c-card-body__wrap" :class="{'c-card-body__wrap--disabled' : item.edit_locked }">
            <label>{{ $t('projects.phase_2') }}</label>
            <b-button variant="primary" size="sm" @click="exportForm(item.id)">
              <b-icon icon="download"></b-icon>&nbsp;
              {{ $t('projects.downloadBtn') }}
            </b-button>
          </div>
          <div class="c-card-body__wrap">
            <hr class="w-100">
            <label>{{ $t('projects.phase_3') }}</label>
            <router-link :to="{name: 'projectsAttachments', params: {id: item.id}}">
              <b-button variant="primary" size="sm">{{ $t('projects.attachments') }}</b-button>
            </router-link>
          </div>
          <hr class="w-100">
          <div class="c-card-body__wrap" :class="{'c-card-body__wrap--disabled' : item.edit_locked }">
            <label>{{ $t('projects.phase_4') }}</label>
            <b-btn v-if="item.status == 'correction_1'" variant="primary" size="sm" @click="setState('fix_1', item.id)">
              {{ $t('projects.checkProjectBtn1') }}
            </b-btn>
            <b-btn v-else variant="primary" size="sm" @click="setState('registered', item.id)">
              {{ $t('projects.checkProjectBtn') }}
            </b-btn>
          </div>

        </b-card>
      </div>
    </div>
    <!-- admin  -->
    <div v-else class="row">
      <div class="c-page__table-scrollable-wrap">
        <table class="table c-page__table">
          <thead>
          <tr>
            <th>{{ $t('projects.tableColDate') }}</th>
            <th class="c-card-body__blue-form" style="padding-left: 0; min-width: 95px">
              <b-select v-model="permanentFilter.project_type" :title="$t('projects.type')">
                <b-form-select-option v-for="type in types" :key="'select_'+type.key" :value="type.key">
                  {{ type.value }}
                </b-form-select-option>
              </b-select>
            </th>
            <th class="c-card-body__blue-form">
              <span class="d-flex align-items-center">
                <b-input v-model="permanentFilter.number_of_call"
                         :placeholder="$t('projects.form.numberOfCall')" :title="$t('projects.form.numberOfCall')"></b-input>&nbsp;
              </span>
            </th>
            <th>
              <span class="d-flex align-items-center">
                <b-input @keyup="setFilterCol('reg_number')" v-model="filter.reg_number"
                         :placeholder="$t('projects.tableColRegN')"></b-input>&nbsp;
                <b-icon @click="sortArr('reg_number')"
                        :icon="(direction == 1)? 'sort-alpha-down' : 'sort-alpha-up'"
                        class="ml-2 clickable" aria-hidden="true"></b-icon>
              </span>
            </th>
            <!--
            <th>
                <span class="d-flex align-items-center">
                    <b-input @keyup="setFilterCol('call')" v-model="filter.call" :placeholder="$t('projects.tableColCall')" ></b-input>
                    <b-icon @click="sortArr('call')" :icon="(direction == 1)? 'sort-alpha-down' : 'sort-alpha-up'" class="ml-2 clickable" aria-hidden="true"></b-icon>
                </span>
            </th>
            -->
            <th>
                            <span class="d-flex align-items-center">
                                <b-input @keyup="setFilterCol('user_name')" v-model="filter.user_name"
                                         :placeholder="$t('projects.tableColUserName')"></b-input>
                                <b-icon @click="sortArr('user_name')"
                                        :icon="(direction == 1)? 'sort-alpha-down' : 'sort-alpha-up'"
                                        class="ml-2 clickable" aria-hidden="true"></b-icon>
                            </span>
            </th>
            <th>
                            <span class="d-flex align-items-center">
                                <b-input @keyup="setFilterCol('name')" v-model="filter.name"
                                         :placeholder="$t('projects.tableColName')"></b-input>
                                <b-icon @click="sortArr('name')"
                                        :icon="(direction == 1)? 'sort-alpha-down' : 'sort-alpha-up'"
                                        class="ml-2 clickable" aria-hidden="true"></b-icon>
                            </span>
            </th>
            <th colspan="2">
              <b-select @change="setFilterCol('state')" v-model="filter.state">
                <b-form-select-option value="">{{ $t('statesShortcut.all_filter') }}</b-form-select-option>
                <b-form-select-option v-for="state in states" :key="'select_'+state" :value="state">
                  {{ $t('statesShortcut["' + state + '"]') }}
                </b-form-select-option>
              </b-select>
            </th>
          </tr>
          </thead>
          <tbody>
          <template v-for="item in filteredData">
            <tr :key="item.id">
              <td>
                {{ item.public_date.date | moment("DD/MM/YYYY HH:mm:ss") }}
              </td>
              <td class="">
                <span v-if="item.project_type == 1">{{$t('projects.type_1')}}</span>
                <span class="text-nowrap" v-if="item.project_type == 2">P-T-P</span>
              </td>
              <td class="text-center">
                {{item.number_of_call}}
              </td>
              <td class="text-center text-nowrap">
                {{item.reg_number}}
              </td>
              <td>
                <div class="text-clamp-3">{{ item.user_name }}</div>
              </td>
              <td>
                <router-link :to="{name: 'projectsPreview', params: {id: item.id}}">
                  {{ item['name_' + $i18n.locale] }}
                </router-link>
              </td>
              <td>
                <span v-if="item.status!=''">
                    {{ $t('statesBtn.' + item.status) }}
                </span>
              </td>
              <td class="text-right c-page__table-edit">
                <router-link :to="{name: 'projectsPreview', params: {id: item.id}}">
                  <b-icon class="clickable" icon="pencil-square" variant="primary"></b-icon>
                </router-link>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>




    <router-view @loadData="loadData()"></router-view>
  </b-container>

</template>

<script>

export default {
  name: 'projects',
  components: {},
  data() {
    return {
      posts: [],
      settingsData: [],
      export_number_of_call: 0,
      export_type: 1,
      sortByFilter: '',
      sortByData: '',
      direction: 1,
      permanentFilter: {
        project_type: 'all',
        number_of_call: ''
      },
      filter: {
        reg_number: '',
        call: '',
        name: '',
        user_name: '',
        state: '',
      }
    }
  },
  methods: {
    async loadData() {
      try {
        const response = await this.$http({
          url: process.env.VUE_APP_SERVER + 'api/articles/list.php',
          headers: {'content-type': 'application/x-www-form-urlencoded'},
          method: 'GET'
        });

        this.posts = response.data;

        this.posts.forEach(item => { // reg. číslo se rozšíří i o typ projektu
            item.reg_number = 'SPF-0' + String(item.project_type) + '-' + String(item.reg_number).padStart(3, '0');
        });

      } catch (error) {
        console.log("error", error);
        this.$eventHub.$emit('openMessageController', 'error', 'no data');
      }

    },
    async loadSettingsData() {
      try {
        const response = await this.$http({
          url: process.env.VUE_APP_SERVER + 'api/settings/list.php',
          headers: {'content-type': 'application/x-www-form-urlencoded'},
          params: {'id': this.id},
          method: 'GET'
        });

        this.settingsData = response.data.records;
        this.export_number_of_call =  this.settingsData.number_of_call_1;

      } catch (error) {
        console.log("error", error);
        this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
      }

    },
    async addItem() {

      try {
        const response = await this.$http({
          url: process.env.VUE_APP_SERVER + 'api/articles/add-item.php',
          headers: {'content-type': 'application/x-www-form-urlencoded'},
          method: 'POST'
        });

        await this.$router.push({name: 'projectsEdit', params: {id: response.data.id}});
        await this.loadData();

      } catch (error) {
        await this.loadData();
        console.log("error", error);
      }

    },
    async setState(status, id) {
      if (status == 'registered' || status == 'fix_1') {
        if (!confirm(this.$t('projects.confirmText'))) {
          return false;
        }
      }
      try {
        await this.$http({
          url: process.env.VUE_APP_SERVER + 'api/articles/set-state.php',
          headers: {'content-type': 'application/x-www-form-urlencoded'},
          data: {
            id: id,
            status: status,
          },
          method: 'POST'
        });


        this.$eventHub.$emit('openMessageController', 'success', this.$t('projects.messageSend'));
        await this.loadData();

      } catch (error) {
        await this.loadData();
        this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
      }

    },
    async exportDatabank() {
      try {
        const response = await this.$http({
          url: process.env.VUE_APP_SERVER + 'api/articles/export-xls.php',
          headers: {'content-type': 'application/x-www-form-urlencoded'},
          params: {
            number_of_call: this.export_number_of_call,
            type: this.export_type,
          },
          method: 'GET'
        });

        const link = document.createElement('a');


        link.href = process.env.VUE_APP_SERVER + response.data.data;
        link.download = '';
        link.target = '__blank';
        link.click();


      } catch (error) {
        console.log("error", error);
      }
    },
    async exportForm(projectId) {
      try {
        const response = await this.$http({
          url: process.env.VUE_APP_SERVER + 'api/articles/export-pdf.php',
          headers: {'content-type': 'application/x-www-form-urlencoded'},
          data: {id: projectId},
          method: 'POST'
        });

        const link = document.createElement('a');
        link.href = process.env.VUE_APP_SERVER + response.data.data;
        link.download = '';
        link.target = '__blank';
        link.click();


      } catch (error) {
        await this.loadData();
        console.log("error", error);
      }
    },
    async exportForm2(projectId) {
      try {
        const response = await this.$http({
          url: process.env.VUE_APP_SERVER + 'api/articles/export-2-pdf.php',
          headers: {'content-type': 'application/x-www-form-urlencoded'},
          data: {id: projectId},
          method: 'POST'
        });

        const link = document.createElement('a');
        link.href = process.env.VUE_APP_SERVER + response.data.data;
        link.download = '';
        link.target = '__blank';
        link.click();


      } catch (error) {
        await this.loadData();
        console.log("error", error);
      }
    },
    sortArr(type) {
      this.direction = this.direction * -1;
      this.setFilterCol(type);

    },
    setFilterCol(type) {
      if (type == 'call') {
        this.sortByFilter = 'call';
        this.sortByData = 'number_of_call';
      } else if (type == 'name') {
        this.sortByFilter = 'name';
        this.sortByData = 'name_' + this.$i18n.locale;
      } else if (type == 'user_name') {
        this.sortByFilter = 'user_name';
        this.sortByData = 'user_name';
      } else if (type == 'state') {
        this.sortByFilter = 'state';
        this.sortByData = 'status';
      } else if (type == 'reg_number') {
        this.sortByFilter = 'reg_number';
        this.sortByData = 'reg_number';
      }
      for (let item in this.filter) {
        if (type != item) {
          this.filter[item] = '';
        }
      }
    },
    setNumberOfCall() {
      this.export_number_of_call = (this.export_type == 1) ?  this.settingsData.number_of_call_1 : this.settingsData.number_of_call_2;
    }
  },
  computed: {
    filteredData() {
      let result = this.posts;

      if (this.permanentFilter.project_type != 'all') {
        result = result.filter(item => {
          let project_type_val = this.permanentFilter.project_type.toString().toLowerCase();
          return item['project_type'].toString().toLowerCase().indexOf(project_type_val) > -1
        })
      }

      if (this.permanentFilter.number_of_call != '' ) {
        result = result.filter(item => {
          let number_of_call_val = this.permanentFilter.number_of_call.toString().toLowerCase();
          return item['number_of_call'].toString().toLowerCase().indexOf(number_of_call_val) > -1
        })
      }

      if (result != 0 && this.sortByData != '') {
        result = result.filter(item => {
          let filter = this.filter[this.sortByFilter].toString().toLowerCase();
          return item[this.sortByData].toString().toLowerCase().indexOf(filter) > -1
        })
      }
      if (this.sortByData != '') {
        result.sort((a, b) => {
          const valA = a[this.sortByData].toString().toLowerCase();
          const valB = b[this.sortByData].toString().toLowerCase();

          return valA.localeCompare(valB, 'cs', {sensitivity: 'accent'}) * this.direction;
        });
      }

      return result;

    },
    projectType() {
      return [
        {value: 1, text: this.$t('projects.form.project_type_1')},
        {value: 2, text: this.$t('projects.form.project_type_2')}
      ]
    },
    permissionsOptions: function () {
      return this.$store.getters.getUserRoles;
    },
    role: function () {
      try {
        return this.$store.getters.user.permission;
      } catch (err) {
        return false
      }
    },
    lang: function () {
      return this.$i18n.locale;
    },
    states() {
      let states = this.$store.getters.states;
      if (states.length !== 0) {
        return states.user.concat(states.admin);
      } else {
        return [];
      }
    },
    types: function () {
      if (this.lang == 'cs') {
        return [
          { key:'all', value: 'Vše'},
          { key:'1', value: 'KCR'},
          { key:'2', value: 'P-T-P'}
        ]
      } else {
        return [
          { key:'all', value: 'Alle'},
          { key:'1', value: 'KT'},
          { key:'2', value: 'P-T-P'}
        ]
      }
    }
  },
  mounted() {
    this.loadData();
    this.loadSettingsData();
  }
}
</script>

<style lang="scss" scoped>
.c-card-body {
  min-height: 400px;

  &--new {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;

    &:hover {
      .c-card-body__icon-wrap {
        transform: scale(1.1);
      }
    }
  }

  &__icon-wrap {
    font-size: 45px;
    transition: transform 250ms;
  }

  &__wrap {
    &--disabled {
      pointer-events: none;
      opacity: .4;
      user-select: none;
    }
  }

  label {
    width: 100%;
  }
  &__blue-form {
    .form-control, .custom-select {
      border-color:  #008fd7;
    }
  }
}

.text-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

}
</style>
